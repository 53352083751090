body {
    font-family: Helvetica, Arial, sans-serif;
    background-color: rgb(29, 29, 29);
    font-size: 14px;
    color: white;
}


.matrix {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 45vh 45vh;
    row-gap: 10px;
    column-gap: 10px;
    padding: 1vh;
}

.quadrant {
    background-color: rgb(53, 48, 48);
    overflow-y: auto;
    border-radius: 5px;
}

.quadranttitle1 {
    color:rgb(187, 22, 22);
}

.quadranttitle2 {
    color:rgb(206, 193, 20);
}

.quadranttitle3 {
    color:rgb(79, 77, 189);
}

.quadranttitle4 {
    color:rgb(69, 187, 22);
}

.quadranttitle {
    padding: 7px;
}

.quadrantlist {
    padding: 1vh;
    vertical-align: middle;
}

.task {
    border-bottom: 1px solid rgb(126, 126, 126);
    padding: 5px;
}

.task:hover {
    background-color: rgb(126, 126, 126);
}

.taskname {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: white;
}

input[type=checkbox] {
    margin-left: 10px;
    margin-right: 5px;
}

.taskname:hover {
    background-color: gray;
}

.tasklist {
    display: inline;
    float: right;
    font-size: 0.8em;
    font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 531px) {
    .matrix { display: flex; flex-flow: column; }
    .quadrant { height: 20vh; }
}
